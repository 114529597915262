import { ThemeService } from "@iqm-ui-library/booster";
import {theme, ThemeConfig, Card} from "antd";

export const customThemeConfig:ThemeConfig = {
    // algorithm: [theme.defaultAlgorithm],
    token : {
        fontFamily: "MuseoSans",
    },
    components : {
        Button : {
            colorBorder: '#d9d9d9',
            borderRadius: 4,
            colorPrimary: '#1677FF',
            colorPrimaryHover:'#1677FF'
        },
        Layout:{
            headerBg: "#000000",
            headerColor: "#FFFFFF",
            bodyBg:"#FFFFFF"
        },
        Card: {
            colorBorderSecondary: 'rgba(0, 0, 0, 0.15)',
        },
        Table: {
            colorBorderSecondary: 'rgba(0, 0, 0, 0.06)'
        }
    }
}

export const IQMAdvReconciliationTheme: ThemeConfig = {
    ...ThemeService.changeTheme('CustomTheme'),
    // Enable below line to change the theme to default
    // ...ThemeService.changeTheme('IQDefault'),
    components : {
        Layout: {
            headerBg: "#000000",
            headerColor: "#FFFFFF",
            bodyBg:"#FFFFFF"
        }
    }
};