import { createBrowserRouter, createHashRouter, Navigate } from "react-router-dom";
import { lazy } from "react";

import { AllReconciliations } from "./components/allReconciliations/allReconciliations";
import { RuleSummaryService2 } from "./services/rule.service";
import { TemplateService2 } from "./services/template.service";
import { ReconciliationDetailsService } from "./services/reconciliationDetails.service";
import { ReconciliationHome } from "./ReconciliationHome";
import { IReconciliationStatus } from "./components/reconciliationDetails/reconciliationDetailsPage.interface";
import { dateFormat } from "./components/utils/commonFunctions";

const ReconciliationForm = lazy(() => import("./components/simpleFormPage/reconciliationForm").then(module => ({ default: module.ReconciliationForm })));
const AllTemplates = lazy(() => import('./components/template/allTemplates').then(module => ({ default: module.AllTemplates })));
const TemplateForm = lazy(() => import('./components/template/templateForm').then(module => ({ default: module.TemplateForm })));
const AllRules = lazy(() => import('./components/reconciliationRules/allRules').then(module => ({ default: module.AllRules })));
const RulesetForm = lazy(() => import('./components/reconciliationRules/ruleSetForm').then(module => ({ default: module.RulesetForm })));
const ReconciliationDetailsPage = lazy(() => import("./components/reconciliationDetails/reconciliationDetailsPage").then(module => ({ default: module.ReconciliationDetailsPage })));
const ReconciliationActionButtons = lazy(() => import("./components/reconciliationDetails/reconciliationDetailsPage").then(module => ({ default: module.ReconciliationActionButtons })));

export enum ReconciliationRoutes {
    AllReconciliations = "/reconciliations",
    NewReconciliation = "/reconciliations/new",
    Templates = "/templates",
    CreateTemplate = "/templates/new",
    EditTemplate = "/templates/:id/edit",
    Rulesets = "/rulesets",
    CreateRuleset = "/rulesets/new",
    EditRuleset = "/rulesets/:id/edit",
    ReconciliationDetails = "/reconciliations/:id",
}

const GetTemplateById = async ({ params }: any) => {
    const { id } = params;
    let returnVal = null;
    await TemplateService2.getTemplateById(id).then((res) => {
        returnVal = res.data;
    }).catch(() => {
        returnVal = false;
    })
    return returnVal;
}

const GetRulesetById = async ({ params }: any) => {
    const { id } = params;
    let returnVal = null;
    await RuleSummaryService2.getDataForRuleset(id).then((res) => {
        returnVal = res.data;
    }).catch(() => {
        returnVal = false;
    })
    return returnVal;
}

const getReconciliationDetails = async ({ params }: any) => {
    const { id } = params;
    let returnVal = null;
    await ReconciliationDetailsService.getReconciliationById(id).then((res) => {
        if (res?.data) {
            returnVal = res.data;
            returnVal.customStatus = returnVal.status === IReconciliationStatus.ReadyToReview ? 'Ready to review' : returnVal.status
            returnVal.key = returnVal.id;
            returnVal.reconciliationPeriod = `${dateFormat(returnVal?.periodStartDateUtc)} - ${dateFormat(returnVal?.periodEndDateUtc)}`
        }
    }).catch(() => {
        returnVal = false;
    })
    return returnVal;
}

const reconciliationRouter = createHashRouter([
    {
        path: "/",
        element: <ReconciliationHome />,
        children: [
            {
                path: ReconciliationRoutes.AllReconciliations,
                element: <AllReconciliations />,
                handle: {
                    crumb: () => 'All reconciliations',
                    header: "Reconciliations",
                    buttons: {
                        label: "Start reconciliation",
                        key: ReconciliationRoutes.NewReconciliation,
                    }
                },
            },
            {
                path: ReconciliationRoutes.ReconciliationDetails,
                element: <ReconciliationDetailsPage/>,
                loader: getReconciliationDetails,
                handle: {
                    crumb: () => '',
                    buttonComponent: <ReconciliationActionButtons />
                },
            },
            {
                path: ReconciliationRoutes.NewReconciliation,
                element: <ReconciliationForm />,
                handle: {
                    crumb: () => 'New reconciliation',
                },
            },
            {
                path: ReconciliationRoutes.Templates,
                element: <AllTemplates />,
                handle: {
                    crumb: () => 'Template management',
                    header: "Template management",
                    buttons: {
                        label: "Create template",
                        key: ReconciliationRoutes.CreateTemplate,
                    }
                },
            },
            {
                path: ReconciliationRoutes.CreateTemplate,
                element: <TemplateForm />,
                handle: {
                    crumb: () => 'New template',
                },
            },
            {
                path: ReconciliationRoutes.EditTemplate,
                element: <TemplateForm />,
                loader: GetTemplateById,
                handle: {
                    crumb: () => 'Edit template',
                },
            },
            {
                path: ReconciliationRoutes.Rulesets,
                element: <AllRules />,
                handle: {
                    crumb: () => 'Ruleset management',
                    header: "Ruleset management",
                    buttons: {
                        label: "Create ruleset",
                        key: ReconciliationRoutes.CreateRuleset,
                    }
                },
            },
            {
                path: ReconciliationRoutes.CreateRuleset,
                element: <RulesetForm />,
                handle: {
                    crumb: () => 'New ruleset',
                },
            },
            {
                path: ReconciliationRoutes.EditRuleset,
                element: <RulesetForm />,
                loader: GetRulesetById,
                handle: {
                    crumb: () => 'Edit ruleset',
                },
            },
            {
                path: "/",
                element: <Navigate to={ReconciliationRoutes.AllReconciliations} />
            }
        ]
    },
    {
        path: "*",
        element: <Navigate to={ReconciliationRoutes.AllReconciliations} />
    }
], {
    future: {
    v7_relativeSplatPath: true,
}
});

export const burgermenuItems = [
    {
        label: 'ReconcilePro',
        key: '1',
        children: [{
            label: 'All reconciliations',
            key: ReconciliationRoutes.AllReconciliations
        }, {
            label: 'New reconciliation',
            key: ReconciliationRoutes.NewReconciliation
        }, {
            label: 'Template management',
            key: ReconciliationRoutes.Templates
        }, {
            label: 'Ruleset management',
            key: ReconciliationRoutes.Rulesets
        }]
    },
]

export default reconciliationRouter;
