import { Breadcrumb, Button, Col, Row, Tag } from 'antd';
import { startTransition } from 'react';
import { NavLink, useLocation, useMatches, useNavigate } from 'react-router-dom';
import { IReconciliationStatus, IReconciliationStatusColors, Reconciliation } from './components/reconciliationDetails/reconciliationDetailsPage.interface';

const CustomBreadcrumb = () => {
  const breadcrumbList: any = [
    {
      title: <NavLink to="/reconciliations">Home</NavLink>,
    }
  ]

  const matches = useMatches();
  const customMatches = matches
  .filter((match: any) => Boolean(match.handle?.crumb));

  const navigate = useNavigate();
  const location = useLocation();
  let selectedItem;
  if (location?.pathname?.includes('reconciliations/') && !location?.pathname?.includes('reconciliations/new')) {
    const activeRoute = customMatches?.find((match: any) => match.handle);
    selectedItem = activeRoute?.data as Reconciliation;
  }

  let elm: React.ReactElement = <></>;
  if (selectedItem) {
    elm = <><span style={{ marginRight: '10px' }}>{selectedItem?.name + ' (' + selectedItem?.reconciliationPeriod + ') '}</span> <Tag color={IReconciliationStatusColors[selectedItem?.status as keyof typeof IReconciliationStatusColors]}>{selectedItem?.status === IReconciliationStatus.ReadyToReview ? 'Ready to review' : selectedItem?.status}</Tag></>
  };
  const crumbs = customMatches?.map((match: any) => match.handle.crumb());
  if (location?.pathname?.includes('reconciliations/') && !location?.pathname?.includes('reconciliations/new')) {
    breadcrumbList.push({
      title: elm
    })
  } else {
    crumbs.map((crumb: any) => {
      breadcrumbList.push({
        title: crumb
      })
    })
  }

  const activeRoute = matches
    .filter((match: any) => Boolean(match.handle))
    .find((match: any) => {
      return match.handle
    }) as { handle: { buttons: { key: string, label: string }, buttonComponent: React.ReactElement } };

  const onButtonClick = () => {
    startTransition(() => {
      navigate?.(activeRoute?.handle?.buttons?.key)
    })
  }

  return <div style={{ padding: '16px 48px', borderBottom: '1px solid rgb(0, 0, 0, 0.15)' }}>
    <Row gutter={[16, 24]} align={"middle"}>
      <Col span={14}>
        <Breadcrumb items={breadcrumbList} />
      </Col>
      <Col span={10}>
        <Row justify={"end"}>
          {activeRoute?.handle?.buttons && <Button type={"primary"} onClick={onButtonClick}>{activeRoute?.handle?.buttons?.label}</Button>}
          {activeRoute?.handle?.buttonComponent}
        </Row>
      </Col>
    </Row>
  </div>
};

export default CustomBreadcrumb;