import { Outlet, useLocation, useMatches, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "./breadcrumb";
import { Typography } from "antd";
import { CustomHeader } from "./components/header/customHeader";
import { ReconciliationRoutes } from "./router";
import { startTransition, useEffect, useState } from "react";

export function ReconciliationHome() {
    const matches = useMatches();
    const navigate = useNavigate();
    const activeRoute: any = matches
        .filter((match: any) => Boolean(match.handle))
        .find((match: any) => match.handle);
    const location = useLocation();
    const [selectedPage, setSelectedPage] = useState<string>(activeRoute?.pathname || ReconciliationRoutes.AllReconciliations);

    function handleMenu(e?: any, isReset: boolean = true): void {
        startTransition(() => {
            if (e.key !== selectedPage) {
                navigate(e.key);
            }
        });
    }

    useEffect(() => {
        setSelectedPage(location.pathname)
    }, [location]);

    return <>
        <CustomHeader handleMenu={handleMenu} reconciliationId={location?.state?.reconciliationId} selectedKey={selectedPage} />

        <CustomBreadcrumb />
        <div className={(activeRoute?.pathname.includes('reconciliations/') && !location?.pathname?.includes('reconciliations/new')) ? 'customBackground' : 'homeContent'}>
            {activeRoute?.handle?.header && <Typography.Title style={{ marginBottom: '20px' }} level={2}>{activeRoute?.handle?.header}</Typography.Title>}
            <Outlet />
        </div>
    </>
}
